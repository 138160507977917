import { useQuery } from '@tanstack/react-query';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useBetaEnabled } from 'features';
import * as api from './authentication';
import { InviteTokenResponse } from './authentication';

const BETA_DFC_MAGIC_TOKEN = 'beta-dfc-token-details';

export const useGetTokenDetails = (token?: string, dialect?: string) => {
  const dfcDummyTokenDetailsEnabled =
    useBetaEnabled('DFC') && token === BETA_DFC_MAGIC_TOKEN;

  return useQuery<InviteTokenResponse | false, Error>({
    queryKey: ['tokenDetails', token],
    queryFn: async () => {
      if (dfcDummyTokenDetailsEnabled) {
        return {
          model: 'DFC',
          evaluatorId: 'DUMMY_DFC_EVALUATOR_ID',
          learner: { fullName: 'Homer Simpson' },
        };
      }
      return api.getTokenDetails(token || '', dialect);
    },
    enabled: Boolean(token),
  });
};

function useDialect() {
  const [searchParams] = useSearchParams();
  return searchParams.get('dialect') || undefined;
}

function removeHashSymbol(hash: string) {
  return hash.slice(1);
}

function parseTokenFromHash(hash: string) {
  const hashContent = removeHashSymbol(hash);
  return new URLSearchParams(hashContent).get('token');
}

/**
 * We expect the hash to contain the token in a query string format.
 */
function useToken() {
  const { hash } = useLocation();
  const token = parseTokenFromHash(hash);
  if (!token) throw new Error('Missing token');
  return token;
}

async function validateToken(token: string, dialect?: string) {
  const response = await api.validateToken(token, dialect);
  return {
    ...response,
    token,
    dialect,
  };
}

export function useValidateToken() {
  const token = useToken();
  const dialect = useDialect();

  return useQuery({
    queryKey: ['tokenDetails', token],
    queryFn: () => validateToken(token, dialect),
  });
}
