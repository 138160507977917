import { unauthenticatedFetch } from './fetch';

export type InviteTokenResponse =
  | {
      model: 'DISCOVERY' | 'DDDRS' | 'EXPLORE';
      evaluatorId: string;
    }
  | {
      model: 'DFC';
      evaluatorId: string;
      learner: {
        fullName: string;
      };
    };

export const getTokenDetails = async (
  token: string,
  dialect?: string,
): Promise<InviteTokenResponse | false> => {
  const query = dialect ? `?dialect=${dialect}` : '';
  const response = await unauthenticatedFetch(
    `/api/v1/invite/${token}${query}`,
  );
  if (!response.ok) {
    return false;
  }
  return response.json();
};

export async function validateToken(
  token: string,
  dialect?: string,
): Promise<InviteTokenResponse> {
  const query = dialect ? `?dialect=${dialect}` : '';
  const response = await unauthenticatedFetch(
    `/api/v1/invite/${token}${query}`,
  );

  if (!response.ok) {
    throw new Error(response.status.toString());
  }

  return response.json();
}
