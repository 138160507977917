import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { Controller, useForm } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import {
  ButtonV2,
  CheckboxV2,
  TextV2,
} from '@insights-ltd/design-library/components';
import privacyPolicyURL from 'utils/privacyPolicy';

const RequiredAsterisk = styled('span')(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const PrivacyPolicyCheckboxLabel = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Trans t={t} i18nKey="ui.io.onboarding.non_sign_up.copyright_notice">
        <Link
          href={privacyPolicyURL(i18n.language)}
          target="_blank"
          rel="noopener"
        >
          ui.io.onboarding.non_sign_up.copyright_notice
        </Link>
      </Trans>
      <RequiredAsterisk aria-hidden="true">{`(${t(
        'ui.io.about_you.required_field',
      )})`}</RequiredAsterisk>
    </>
  );
};

type FormData = {
  privacyPolicy: boolean;
};

export const PrivacyPolicyForm = ({
  privacyPolicyAccepted = false,
  onComplete,
}: {
  privacyPolicyAccepted?: boolean;
  onComplete: () => void;
}) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      privacyPolicy: privacyPolicyAccepted,
    },
    mode: 'onChange',
  });

  const error = Boolean(errors.privacyPolicy);

  return (
    <form onSubmit={handleSubmit(onComplete)} noValidate>
      <FormControl required error={error}>
        <FormControlLabel
          control={
            <Controller
              render={({
                field: { onChange, onBlur, value, name },
                formState: { errors: formStateErrors },
              }) => (
                <CheckboxV2
                  onBlur={onBlur}
                  onChange={(newValue: any) => onChange(newValue)}
                  checked={value}
                  name={name}
                  required
                  color="primary"
                  data-testid="privacy-policy-checkbox"
                  inputProps={{
                    'aria-describedby': `privacy-policy-error-label`,
                    'aria-required': 'true',
                    'aria-invalid': !!formStateErrors[name],
                    role: 'checkbox',
                  }}
                  sx={{ width: '42px' }}
                />
              )}
              name="privacyPolicy"
              control={control}
              rules={{ required: true }}
            />
          }
          label={<PrivacyPolicyCheckboxLabel />}
        />
        {error && (
          <TextV2
            variant="bodySmall"
            id="privacy-policy-error-label"
            role="alert"
            color="error"
          >
            {t('ui.io.privacy_policy.required_error')}
          </TextV2>
        )}
      </FormControl>
      <Stack direction="row" justifyContent="flex-end" my={3}>
        <ButtonV2
          disableRipple
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          buttonText={t('ui.io.onboarding.get_started')}
        />
      </Stack>
    </form>
  );
};
