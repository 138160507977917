import * as React from 'react';
import { ReactNode, createContext } from 'react';
import { InviteTokenResponse } from 'api/authentication';
import { useValidateToken } from 'api/authentication.hooks';
import { TokenError } from 'pages/Evaluator/TokenError';
import { FullScreenSpinner } from 'components/FullScreen';
import { useTranslation } from 'react-i18next';

const Context = createContext<TokenContext | undefined>(undefined);

export const TokenProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const { data, isPending, isError } = useValidateToken();
  if (isPending) {
    return <FullScreenSpinner message={t('ui.io.loading')} />;
  }
  if (isError) {
    return <TokenError />;
  }
  return <Context.Provider value={data}>{children}</Context.Provider>;
};

type TokenContext = InviteTokenResponse & {
  token: string;
  dialect?: string;
};
