import { LanguageDetails } from '@insights-ltd/design-library/utils';
import { Evaluator } from 'types/evaluator';
import { DIALECTS, Dialects } from 'variables/dialects';

const evaluatorLocales: EvaluatorLocale[] = [
  {
    tag: DIALECTS.AFRIKAANS,
    text: 'Afrikaans (ZA)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.BAHASA,
    text: 'Bahasa (ID)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.CZECH,
    text: 'Čeština (CZ)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.DANISH,
    text: 'Dansk (DK)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE', 'DFC'],
  },
  {
    tag: DIALECTS.GERMAN,
    text: 'Deutsch (DE)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE', 'DFC'],
  },
  {
    tag: DIALECTS.ESTONIAN,
    text: 'Eesti keel (EE)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.ENGLISH,
    text: 'English (GB)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE', 'DFC'],
  },
  {
    tag: DIALECTS.ENGLISH_SOUTH_AFRICA,
    text: 'English (ZA)',
    supportedEvaluators: ['DISCOVERY'],
  },
  {
    tag: DIALECTS.SPANISH_SPAIN,
    text: 'Español (ES)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE', 'DFC'],
  },
  {
    tag: DIALECTS.SPANISH_MEXICO,
    text: 'Español (MX)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.FRENCH,
    text: 'Français (FR)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE', 'DFC'],
  },
  {
    tag: DIALECTS.FRENCH_CANADA,
    text: 'Français (CA)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.CROATIAN,
    text: 'Hravtski (HR)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.ICELANDIC,
    text: 'Íslenska (IS)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.ITALIAN,
    text: 'Italiano (IT)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.LITHUANIAN,
    text: 'Lietuvių k. (LT)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.HUNGARIAN,
    text: 'Magyar (HU)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.DUTCH,
    text: 'Nederlands (NL)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE', 'DFC'],
  },
  {
    tag: DIALECTS.NORWEGIAN_BOKMAL,
    text: 'Norsk bokmål (NO)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.POLISH,
    text: 'Polski (PL)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.PORTUGUESE_BRAZIL,
    text: 'Português (BR)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.PORTUGUESE_PORTUGAL,
    text: 'Português (PT)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.ROMANIAN,
    text: 'Română (RO)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.SLOVAKIAN,
    text: 'Slovenčina (SK)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.SLOVENIAN,
    text: 'Slovenščina (SI)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.SERBIAN_LATIN,
    text: 'Srpski latinica (RS)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.FINNISH,
    text: 'Suomi (FI)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.SWEDISH,
    text: 'Svenska (SE)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.VIETNAMESE,
    text: 'Tiếng Việt (VN)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.TURKISH,
    text: 'Türkçe (TR)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.GREEK,
    text: 'Ελληνικά (GR)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.BULGARIAN,
    text: 'Български (BG)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.RUSSIAN,
    text: 'Русский (RF)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.UKRAINIAN,
    text: 'Українська (UA)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.GEORGIAN,
    text: 'ქართული (GE)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.KOREAN,
    text: '한국어 (KR)',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.JAPANESE,
    text: '日本語（JP）',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.CHINESE_SIMPLIFIED,
    text: '中文（CN）',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
  {
    tag: DIALECTS.CHINESE_TRADITIONAL_HK,
    text: '中文（HK）',
    supportedEvaluators: ['DISCOVERY', 'DDDRS', 'EXPLORE'],
  },
];

function mapLocaleToOption(locale: EvaluatorLocale): LanguageDetails {
  return { ...locale, enabled: true };
}

export function localeOptionsForEvaluator(
  model?: Evaluator,
): LanguageDetails[] {
  return evaluatorLocales
    .filter((locale) => !model || locale.supportedEvaluators.includes(model))
    .map(mapLocaleToOption);
}

type EvaluatorLocale = {
  tag: Dialects;
  text: string;
  supportedEvaluators: Evaluator[];
};
