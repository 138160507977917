import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FullScreenError } from 'components/FullScreen';

export const TokenError = () => {
  const { t } = useTranslation();
  return (
    <FullScreenError
      heading={t('ui.io.error.invite_expired.title')}
      message={t('ui.io.error.invite_expired.message')}
    />
  );
};
