import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Button, styled, Typography } from '@mui/material';
import DefaultLayout from 'components/layout/DefaultLayout';
import { SvgDiscoveryProfile } from '@insights-ltd/design-library/components';
import {
  EvaluatorSource,
  Evaluator as EvaluatorType,
  ExploreProfileResponse,
} from 'types/evaluator';
import { EVALUATOR_TITLE_KEYS, PAGE_TITLE_KEYS } from 'variables';
import waitingGif from './waiting_animation.gif';

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexShrink: '0',
  alignItems: 'center',
  maxWidth: '450px',
});

const StyledDefaultLayout = styled(DefaultLayout)({
  color: 'red !important',
});

const Thanks = ({
  noSignUp,
  model,
  mutateResponse,
  evaluatorSource = null,
}: {
  noSignUp?: boolean;
  model: EvaluatorType;
  mutateResponse?: ExploreProfileResponse;
  evaluatorSource?: EvaluatorSource;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isExploreEvaluator = model === 'EXPLORE';
  const isApiEvaluator = evaluatorSource === 'INSIGHTS_API';
  let secondaryText: string;

  if (isApiEvaluator) {
    secondaryText = t('ui.io.evaluator.complete.non_sign_up.thanks_complete');
  } else {
    secondaryText = isExploreEvaluator
      ? t('ui.io.evaluator.explore.complete_message')
      : t('ui.io.evaluator.complete.look_at_profile');
  }

  useEffect(() => {
    if (isExploreEvaluator && mutateResponse) {
      const timer = setTimeout(() => {
        navigate(
          `/profile/${mutateResponse.profileId}/${mutateResponse.apiToken}`,
        );
      }, 3000);
      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
    return (): void => {};
  }, [isExploreEvaluator, navigate, mutateResponse]);

  if (model !== 'DFC') {
    return (
      <DefaultLayout>
        <Helmet
          title={`${t(PAGE_TITLE_KEYS.THANK_YOU)} -
            ${t(EVALUATOR_TITLE_KEYS.DDDRS)}`}
        />
        <StyledContainer>
          {isExploreEvaluator ? (
            <img src={waitingGif} alt="progress-graphic" width={200} />
          ) : (
            <SvgDiscoveryProfile
              data-testid="profile-icon-svg"
              aria-hidden="true"
            />
          )}
          <Typography
            component="h2"
            variant="h2"
            sx={{ marginBottom: '2rem', textAlign: 'center' }}
          >
            {isExploreEvaluator
              ? t('ui.io.evaluator.explore.complete_generating')
              : t('ui.io.evaluator.complete.thanks')}
          </Typography>
          <Typography
            color="textSecondary"
            sx={{ marginBottom: '2rem', textAlign: 'center' }}
          >
            {secondaryText}
          </Typography>
          {!noSignUp && !isExploreEvaluator && (
            <Button
              disableRipple
              component={Link}
              variant="contained"
              color="primary"
              size="large"
              to="/experience"
            >
              {t('ui.io.evaluator.complete.back_to_event')}
            </Button>
          )}
        </StyledContainer>
      </DefaultLayout>
    );
  }

  return (
    <StyledDefaultLayout>
      <Helmet
        title={`${t(PAGE_TITLE_KEYS.THANK_YOU)} -
          ${t(EVALUATOR_TITLE_KEYS.DFC)}`}
      />
      <StyledContainer>
        <SvgDiscoveryProfile
          data-testid="profile-icon-svg"
          aria-hidden="true"
        />
        <Typography
          component="h2"
          variant="h2"
          sx={{ marginBottom: '2rem', textAlign: 'center' }}
        >
          {t('ui.io.evaluator.complete.non_sign_up.thanks')}
        </Typography>
        <Typography
          color="textSecondary"
          sx={{ marginBottom: '2rem', textAlign: 'center' }}
        >
          {t('ui.io.evaluator.dfc.submittion-completed')}
        </Typography>
        {!noSignUp && (
          <Button
            disableRipple
            component={Link}
            variant="contained"
            color="primary"
            size="large"
            to="/experience"
          >
            {t('ui.io.evaluator.complete.back_to_event')}
          </Button>
        )}
      </StyledContainer>
    </StyledDefaultLayout>
  );
};

export default Thanks;
