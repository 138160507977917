import React from 'react';
import { Box, Stack, Theme, useMediaQuery, useTheme } from '@mui/material';
import {
  LanguageSelect,
  SvgInsightsLogo,
  TextV2,
} from '@insights-ltd/design-library/components';
import { useTranslation } from 'react-i18next';
import { LanguageDetails } from '@insights-ltd/design-library/utils';

type HeaderProps = {
  title: string;
  languageOptions?: LanguageDetails[];
};

const DefaultHeader = ({ title, languageOptions }: HeaderProps) => {
  const mediumScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md'),
  );
  const theme = useTheme();
  const { i18n } = useTranslation();

  const onLangSelect = async (language: string) => {
    await i18n.changeLanguage(language);
  };
  return (
    <Box sx={{ borderBottom: 1, borderColor: 'grey.400' }}>
      <Stack
        translate="no"
        direction="row"
        height="4.5rem"
        justifyContent="space-between"
        alignItems="center"
        px={4}
      >
        <SvgInsightsLogo
          data-testid="logo-svg"
          fill={theme.palette.blue.main}
          height="2rem"
          title="Insights Logo"
          aria-label="Insights"
        />
        <div>
          {mediumScreen && (
            <TextV2 component="h1" variant="h4">
              {title}
            </TextV2>
          )}
        </div>
        <Box minWidth="10rem">
          {languageOptions ? (
            <LanguageSelect
              id="evaluator-language-select"
              onChange={(event) => onLangSelect(event.target.value as string)}
              languageOptions={languageOptions}
              value={i18n.language}
            />
          ) : null}
        </Box>
      </Stack>
    </Box>
  );
};

export default DefaultHeader;
