import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Divider,
  FormLabel,
  Radio,
  RadioGroup,
  styled,
} from '@mui/material';
import { PROPOSITION_RANKS, PROPOSITION_RANK_ARIA_LABELS } from 'variables';
import { Evaluator, Proposition } from 'types/evaluator';
import useAboveMobile from 'components/hooks/useAboveMobile';
import {
  P,
  radioStyles,
  TextV2,
} from '@insights-ltd/design-library/components';
import { getLabelledById } from './validationUtils';

// RHF does not deal well with the dots in the proposition keys, we
// use these functions to replace the dots with "__" to register the inputs
// and place them back before persisting
export const escapeAccessors = (string: string) => string.split('.').join('__');
export const unescapeAccessors = (string: string) =>
  string.split('__').join('.');

const PropositionRowContainer = styled('div')<{ aboveMobile: boolean }>(
  ({ aboveMobile }) => ({
    '> fieldset': {
      display: 'flex',
      marginTop: '1rem',
      flexDirection: 'column',
      gap: '1rem',
      ...(aboveMobile && {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '0',
        padding: '0.5rem 0',
      }),
      border: 'none',
      legend: {
        visibility: 'collapse',
      },
    },
  }),
);

const HeaderRowContainer = styled('div')<{ aboveMobile: boolean }>(
  ({ theme, aboveMobile }) => ({
    gap: '1rem',
    ...(aboveMobile && {
      display: 'flex',
      flex: '1',
      marginTop: '0.5rem',
      padding: '0.5rem 0',
      backgroundColor: theme.palette.blue.light,
    }),
  }),
);

const PropositionText = styled('div')<{ aboveMobile: boolean }>(
  ({ aboveMobile }) => ({
    ...(aboveMobile && {
      display: 'flex',
      flex: '0.75',
      alignItems: 'center',
    }),
  }),
);

const RankingLabelsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-around',
  padding: '0.5rem 0',
  top: '0',
  bottom: 'auto',
  position: 'sticky',
  textAlign: 'center',
  backgroundColor: theme.palette.blue.light,
  zIndex: 1,
}));

const RadioContainer = styled('div')<{ aboveMobile: boolean }>(
  ({ aboveMobile }) => ({
    ...(aboveMobile && {
      display: 'flex',
      flex: '1',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
    }),
  }),
);

const RadioCell = styled(P)({
  display: 'flex',
  flex: '1',
  justifyContent: 'center',
  minWidth: '1rem',
});

const RankingLabels = () => {
  const { t } = useTranslation();
  return (
    <RankingLabelsContainer>
      {PROPOSITION_RANKS.map((propositionRank, index) => {
        const value = index.toString();
        return (
          <FormLabel key={value} sx={{ minWidth: '3rem' }}>
            <TextV2
              component="p"
              variant="bodyBold"
              aria-label={t(PROPOSITION_RANK_ARIA_LABELS[propositionRank])}
            >
              {t(
                `ui.io.evaluator.proposition.heading_${propositionRank.toLowerCase()}`,
              )}
            </TextV2>
          </FormLabel>
        );
      })}
    </RankingLabelsContainer>
  );
};

const HeaderRow = ({ evaluatorModel }: { evaluatorModel: Evaluator }) => {
  const { t } = useTranslation();
  const aboveMobile = useAboveMobile();

  return (
    <HeaderRowContainer
      sx={(theme) => ({ backgroundColor: theme.palette.blue.light })}
      aboveMobile={aboveMobile}
    >
      <PropositionText aboveMobile={aboveMobile}>
        {evaluatorModel === 'DFC' && (
          <Box pl={2}>
            <TextV2 component="p" variant="bodyBold">
              {t('ui.io.evaluator.proposition.dfc_info_label')}
            </TextV2>
          </Box>
        )}
      </PropositionText>
      {aboveMobile && (
        <RadioContainer aria-hidden="true" aboveMobile={aboveMobile}>
          {PROPOSITION_RANKS.map((propositionRank) => (
            <RadioCell
              aria-hidden="true"
              aria-label={t(PROPOSITION_RANK_ARIA_LABELS[propositionRank])}
              key={propositionRank}
              variant="body-bold"
            >
              {t(
                `ui.io.evaluator.proposition.heading_${propositionRank.toLowerCase()}`,
              )}
            </RadioCell>
          ))}
        </RadioContainer>
      )}
    </HeaderRowContainer>
  );
};

const PropositionLabel = ({
  text,
  'aria-hidden': ariaHidden = false,
  textId,
}: {
  text: string;
  textId: string;
  ['aria-hidden']?: boolean | 'true' | 'false';
}) => {
  const aboveMobile = useAboveMobile();
  return (
    <PropositionText aria-hidden={ariaHidden} aboveMobile={aboveMobile}>
      <FormLabel>
        <TextV2 component="p" variant="bodyBold" id={textId}>
          {text}
        </TextV2>
      </FormLabel>
    </PropositionText>
  );
};

const FieldsetStyled = styled('fieldset')({
  padding: 0,
  marginInline: 0,
});

type PropositionProps = {
  propositionKey: string;
  propositionText: string;
  errors?: Record<string, unknown>;
};

const PropositionRow = ({
  propositionKey,
  propositionText,
  errors = {},
}: PropositionProps) => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const aboveMobile = useAboveMobile();
  const errorLabelledById = getLabelledById(errors);
  const textId = propositionText.replace(/ /g, '-');
  return (
    <PropositionRowContainer aboveMobile={aboveMobile}>
      <FieldsetStyled>
        <legend data-testid={`${propositionText.split(' ').join('-')}-legend`}>
          {propositionText}
        </legend>
        <PropositionLabel aria-hidden text={propositionText} textId={textId} />
        <Controller
          render={({ field }) => (
            <RadioContainer aboveMobile={aboveMobile}>
              <RadioGroup
                aria-label={propositionText}
                row
                role="radiogroup"
                aria-labelledby={errorLabelledById}
                {...field}
                sx={{
                  flex: '1',
                  justifyContent: 'space-around',
                  paddingRight: 0,
                }}
              >
                {PROPOSITION_RANKS.map((propositionRank, index) => {
                  const value = index.toString();
                  return (
                    <Radio
                      key={propositionRank}
                      value={value}
                      color="default"
                      inputProps={{
                        'aria-label': t(
                          PROPOSITION_RANK_ARIA_LABELS[propositionRank],
                        ),
                        'aria-describedby': textId,
                        role: 'radio',
                      }}
                      sx={radioStyles}
                      disableRipple
                    />
                  );
                })}
              </RadioGroup>
            </RadioContainer>
          )}
          name={escapeAccessors(propositionKey)}
          control={control}
          defaultValue=""
        />
      </FieldsetStyled>
    </PropositionRowContainer>
  );
};

type PropositionsProps = {
  propositions: Proposition[];
  evaluatorModel: Evaluator;
  errors?: Record<string, unknown>;
};

const PropositionsContainer = styled('div')({
  marginTop: '1rem',
  marginBlockEnd: '3rem',
});

const Propositions = ({
  propositions,
  evaluatorModel,
  errors = {},
}: PropositionsProps) => {
  const aboveMobile = useAboveMobile();
  return (
    <PropositionsContainer>
      <HeaderRow evaluatorModel={evaluatorModel} />
      {!aboveMobile && <RankingLabels />}
      {propositions.map((proposition) => (
        <React.Fragment key={proposition.key}>
          <PropositionRow
            propositionKey={proposition.key}
            propositionText={proposition.text}
            errors={errors}
          />
          {aboveMobile && <Divider light />}
        </React.Fragment>
      ))}
    </PropositionsContainer>
  );
};

export default Propositions;
