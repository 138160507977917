import React from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { Evaluator as EvaluatorType } from 'types/evaluator';
import { Stack } from '@mui/material';
import { ColourChartSplitLayout } from 'components';
import { TextV2 } from '@insights-ltd/design-library/components';
import { PAGE_TITLE_KEYS, EVALUATOR_TITLE_KEYS } from 'variables';
import {
  DfcTitle,
  DiscoveryTitle,
} from 'components/layout/ColourChartSplitLayout';
import { PrivacyPolicyForm } from 'components/PrivacyPolicyForm';

type Props = {
  privacyPolicyAccepted?: boolean;
  onComplete: () => void;
  noSignUp?: boolean;
  subjectFullName?: string;
  model: EvaluatorType;
};

const ExploreWelcomeContent = ({
  onComplete,
  privacyPolicyAccepted,
}: Pick<Props, 'privacyPolicyAccepted' | 'onComplete'>) => {
  const { t } = useTranslation();

  return (
    <>
      <TextV2 component="p" color="textSecondary">
        {t('ui.io.onboarding.non_sign_up.welcome.explore.overview')}
      </TextV2>
      <PrivacyPolicyForm
        onComplete={onComplete}
        privacyPolicyAccepted={privacyPolicyAccepted}
      />
      <TextV2 component="p" color="textSecondary" variant="bodyBold">
        {t('ui.io.onboarding.non_sign_up.who_are_insights_heading')}
      </TextV2>
      <TextV2 component="p" color="textSecondary">
        {t('ui.io.onboarding.non_sign_up.who_are_insights_body')}
      </TextV2>
      <TextV2 component="p" color="textSecondary" variant="bodyBold">
        {t('ui.io.onboarding.non_sign_up.what_is_insights_heading')}
      </TextV2>
      <TextV2 component="p" color="textSecondary">
        {t('ui.io.onboarding.non_sign_up.what_is_insights_body')}
      </TextV2>
    </>
  );
};

const DFCWelcomeContent = ({
  subjectFullName,
  onComplete,
  privacyPolicyAccepted,
}: Pick<Props, 'subjectFullName' | 'privacyPolicyAccepted' | 'onComplete'>) => {
  const { t } = useTranslation();
  return (
    <Stack spacing="1rem">
      <TextV2 component="p" color="textSecondary">
        <Trans
          values={{ subjectFullName }}
          components={{
            strong: <TextV2 variant="bodyBold" component="span" />,
          }}
          i18nKey="ui.io.onboarding.welcome.bold.subjectfullname.overview.dfc"
        />
      </TextV2>
      <TextV2 component="p" color="textSecondary">
        {t('ui.io.onboarding.welcome.overview.intro.dfc', {
          subjectFullName,
        })}
      </TextV2>
      <TextV2 component="p" color="textSecondary">
        {t('ui.io.onboarding.welcome.overview.intro.body.dfc', {
          subjectFullName,
        })}
      </TextV2>
      <TextV2 component="p" color="textSecondary">
        {t('ui.io.onboarding.welcome.overview.intro.body.continued.dfc', {
          subjectFullName,
        })}
      </TextV2>
      <PrivacyPolicyForm
        onComplete={onComplete}
        privacyPolicyAccepted={privacyPolicyAccepted}
      />
    </Stack>
  );
};

const WelcomeContent = ({
  privacyPolicyAccepted,
  onComplete,
  noSignUp = false,
  subjectFullName,
  model,
}: Props) => {
  const { t } = useTranslation();

  if (model === 'EXPLORE') {
    return (
      <ExploreWelcomeContent
        onComplete={onComplete}
        privacyPolicyAccepted={privacyPolicyAccepted}
      />
    );
  }

  if (model === 'DFC') {
    return (
      <DFCWelcomeContent
        subjectFullName={subjectFullName}
        onComplete={onComplete}
        privacyPolicyAccepted={privacyPolicyAccepted}
      />
    );
  }

  return (
    <Stack spacing={2}>
      <TextV2 component="p" color="textSecondary">
        {t(
          noSignUp
            ? 'ui.io.onboarding.non_sign_up.welcome.overview'
            : 'ui.io.onboarding.welcome.overview',
        )}
      </TextV2>
      <PrivacyPolicyForm
        onComplete={onComplete}
        privacyPolicyAccepted={privacyPolicyAccepted}
      />
    </Stack>
  );
};

const Welcome = ({
  privacyPolicyAccepted,
  onComplete,
  noSignUp = false,
  subjectFullName,
  model,
}: Props) => {
  const { t } = useTranslation();

  return (
    <ColourChartSplitLayout
      title={t('ui.io.onboarding.welcome_title')}
      chartTitle={model === 'DFC' ? <DfcTitle /> : <DiscoveryTitle />}
      keepBanner
    >
      <Helmet
        title={`${t(PAGE_TITLE_KEYS.WELCOME)} - ${t(
          EVALUATOR_TITLE_KEYS[model],
        )}`}
      />
      <WelcomeContent
        noSignUp={noSignUp}
        privacyPolicyAccepted={privacyPolicyAccepted}
        onComplete={onComplete}
        subjectFullName={subjectFullName}
        model={model}
      />
    </ColourChartSplitLayout>
  );
};

export default Welcome;
