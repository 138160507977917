import React from 'react';
import { Helmet } from 'react-helmet';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ColourChartSplitLayout, {
  DiscoveryTitle,
} from 'components/layout/ColourChartSplitLayout';
import { EVALUATOR_TITLE_KEYS, PAGE_TITLE_KEYS } from 'variables';

const FormLayout: React.FC<{
  step: number;
  stepMax: number;
  subtitle?: string;
  children?: React.ReactNode;
}> = ({ step, subtitle, children, stepMax }) => {
  const { t } = useTranslation();

  return (
    <ColourChartSplitLayout
      title={t('ui.io.about_you.page_title')}
      chartTitle={<DiscoveryTitle />}
      keepBanner
    >
      <Helmet
        title={`${t(PAGE_TITLE_KEYS.ABOUT_YOU)} -
          ${t(EVALUATOR_TITLE_KEYS.DISCOVERY)}`}
      />
      <Typography color="textPrimary" variant="h6" component="h2" gutterBottom>
        {t('ui.io.about_you.part')} {step}&nbsp;/ {stepMax}{' '}
        {subtitle ? `- ${subtitle}` : null}
      </Typography>
      {children}
    </ColourChartSplitLayout>
  );
};

export default FormLayout;
