import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import {
  Box,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ColourChartSplitLayout, {
  DfcTitle,
  DiscoveryTitle,
} from 'components/layout/ColourChartSplitLayout';
import { Evaluator } from 'types/evaluator';
import {
  ButtonV2,
  SvgAlertDiamond,
  TextV2,
} from '@insights-ltd/design-library/components';
import { EVALUATOR_TITLE_KEYS } from 'variables';
import { getTheme } from '@insights-ltd/design-library/themes';

const IconContainer = styled('div')(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: theme.palette.orange.light,
  height: '40px',
  width: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const InformationHeading = () => {
  const { t } = useTranslation();
  const theme = getTheme();
  return (
    <Stack mb={1} direction="row" gap={1.5} alignItems="center">
      <IconContainer>
        <SvgAlertDiamond
          width="20"
          height={20}
          fill={theme.brandColours.darkOrange}
          data-testid="info-icon"
          aria-hidden="true"
        />
      </IconContainer>
      <TextV2 variant="h4" component="h2">
        {t('ui.io.evaluator.instructions.info_title')}
      </TextV2>
    </Stack>
  );
};

const Li = ({ children }: PropsWithChildren) => (
  <Typography color="textSecondary" component="li" lineHeight={1.75}>
    {children}
  </Typography>
);

const dfcInstructionKeys = [
  'ui.io.evaluator.instructions.time_list.item.1.dfc',
  'ui.io.evaluator.instructions.dfc.item.0',
  'ui.io.evaluator.instructions.dfc.item.1',
  'ui.io.evaluator.instructions.dfc.item.2',
  'ui.io.evaluator.instructions.dfc.item.3',
  'ui.io.evaluator.instructions.dfc.item.4',
  'ui.io.evaluator.instructions.dfc.item.5',
  'ui.io.evaluator.instructions.dfc.item.6',
  'ui.io.evaluator.instructions.dfc.item.7',
  'ui.io.evaluator.instructions.dfc.item.8',
  'ui.io.evaluator.instructions.dfc.item.9',
  'ui.io.evaluator.instructions.dfc.item.10',
];

const InformationItems = ({
  model,
  subjectFullName,
}: Omit<Props, 'onNext'>) => {
  const { t } = useTranslation();

  if (model === 'DFC') {
    return dfcInstructionKeys.map((key) => (
      <Li key={key}>{t(key, { subjectFullName })}</Li>
    ));
  }

  return (
    <>
      <Li>{t('ui.io.evaluator.instructions.time_list.item.1')}</Li>
      <Li>{t('ui.io.evaluator.instructions.info_list.item.1')}</Li>
      <Li>{t('ui.io.evaluator.instructions.info_list.item.2')}</Li>
      <Li>{t('ui.io.evaluator.instructions.info_list.item.3')}</Li>
      <Li>
        {model === 'DDDRS' || model === 'EXPLORE'
          ? t('ui.io.evaluator.instructions.info_list.item.4.3drs')
          : t('ui.io.evaluator.instructions.info_list.item.4.idpe')}
      </Li>
      <Li>{t('ui.io.evaluator.instructions.info_list.item.5')}</Li>
      <Li>{t('ui.io.evaluator.instructions.info_list.item.6')}</Li>
    </>
  );
};

type Props = {
  onNext: () => void;
  model: Evaluator;
  subjectFullName?: string;
};

const Instructions = ({ onNext, model, subjectFullName }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isDFC = model === 'DFC';
  const title = isDFC
    ? t('ui.io.evaluator.instructions.dfc.title')
    : t('ui.io.evaluator.instructions.title');

  return (
    <>
      <Helmet title={`${title} - ${t(EVALUATOR_TITLE_KEYS[model])}`} />
      <ColourChartSplitLayout
        chartTitle={model === 'DFC' ? <DfcTitle /> : <DiscoveryTitle />}
        title={title}
        keepBanner
      >
        <Stack gap={2}>
          {!isDFC && (
            <TextV2 component="p" color="textSecondary">
              {t('ui.io.evaluator.instructions.intro')}
            </TextV2>
          )}
          <div>
            <InformationHeading />
            <Box component="ul" m={0} paddingLeft={2.5}>
              <InformationItems
                model={model}
                subjectFullName={subjectFullName}
              />
            </Box>
          </div>
          <Stack mt={5} alignItems={smallScreen ? 'normal' : 'flex-end'}>
            <ButtonV2
              disableRipple
              variant="contained"
              color="primary"
              onClick={onNext}
              size="large"
              buttonText={t('ui.io.navigation.begin')}
            />
          </Stack>
        </Stack>
      </ColourChartSplitLayout>
    </>
  );
};

export default Instructions;
